import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import ArrowRight from '../../assets/icons/baseline-arrow_forward-16px_black.svg'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'

import { Heading } from '../styled/typography'
import { Btn } from '../styled/ui'

const FeaturedProjectWrapper = styled.div`
  height: calc(95vh - 90px);

  position: relative;
  display: grid;
  grid-gap: 5vmax;
  grid-template-columns: 1fr 2fr;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
  & h2 {
    margin-bottom: 0px;
    margin-top: 20px;
  }
  & h2 span {
    display: block;
    color: #00000080;
    font-size: 70%;
    margin-top: 15px;
  }
`
const ImageWrapper = styled.div`
  overflow: hidden;
  transition: all 0.2s;
  &:hover {
    transform: scale(0.9);
  }
  & > div {
    height: 100%;
  }
  & .gatsby-image-wrapper {
    height: 100%;
    transition: all 0.2s;
  }
  &:hover .gatsby-image-wrapper {
    transform: scale(1.3);
  }
`

const Pill = styled.span`
  background: #fbb768;
  color: white;
  font-weight: 500;
  padding: 0px 10px;
  border-radius: 4px;
  box-shadow: 0px 3px 20px #fbb76880;
  display: inline-block;
  margin-bottom: 40px;
`

const BgBlob = styled.span`
  display: block;
  position: absolute;
  width: 100vmax;
  height: 100vmax;
  top: -50vw;
  right: -50vw;
  z-index: -1;
  border-radius: 100%;
  opacity: 0.14;
  @media screen and (max-width: 1024px) {
    & {
      width: 100vmax;
      height: 100vmax;
      top: -20vw;
      right: -60vw;
    }
  }
  @media screen and (max-width: 500px) {
    & {
      width: 100vmax;
      height: 100vmax;
      top: -40vw;
      right: -120vw;
    }
  }
`

const FeaturedProject = () => (
  <StaticQuery
    query={graphql`
      query featuredProjectQuery {
        allAirtable(
          limit: 4
          filter: {
            table: { eq: "Uppdrag" }
            data: { Status: { eq: "Publicerad" }, Utvald: { eq: true } }
          }
          sort: { fields: [data___Genomf_rdes], order: DESC }
        ) {
          edges {
            node {
              id
              data {
                Titel
                Rubrik
                Path
                Color
                Bilder {
                  localFiles {
                    childImageSharp {
                      fluid(
                        maxWidth: 800
                        maxHeight: 750
                        cropFocus: ATTENTION
                        quality: 80
                      ) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const randomProjectNum = Math.floor(
        Math.random() * data.allAirtable.edges.length
      )
      const project = data.allAirtable.edges[randomProjectNum].node

      return (
        <FeaturedProjectWrapper key="project.id">
          <MetaWrapper>
            <div>
              <Pill>Utvalt uppdrag</Pill>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Heading>
                  {project.data.Rubrik}. <span>{project.data.Titel}.</span>
                </Heading>
              </ScrollAnimation>
            </div>
            <div>
              <Btn style={{ marginTop: '40px' }} to={project.data.Path}>
                Läs mer <ArrowRight />
              </Btn>
            </div>
          </MetaWrapper>

          <ImageWrapper>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              delay={500}
              duration={2}
            >
              <Link to={project.data.Path}>
                <Img
                  fluid={
                    project.data.Bilder.localFiles[0].childImageSharp.fluid
                  }
                />
              </Link>
            </ScrollAnimation>
          </ImageWrapper>
          <BgBlob style={{ backgroundColor: project.data.Color }} />
        </FeaturedProjectWrapper>
      )
    }}
  />
)

export default FeaturedProject

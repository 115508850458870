import 'animate.css/animate.min.css'

import {
  Column,
  Container,
  Grid,
  Section,
  TopContainer,
} from '../components/styled/layout'
import { Heading, Intro } from '../components/styled/typography'

import FeaturedProject from '../components/UI/FeaturedProject'
import Layout from '../components/layout'
import { Line } from '../components/styled/ui'
import News from '../components/news/news'
import Projects from '../components/projects/Projects'
import React from 'react'
import SEO from '../components/SEO/SEO'
import ScrollAnimation from 'react-animate-on-scroll'

class IndexPage extends React.Component {
  state = {}

  render() {
    return (
      <React.Fragment>
        <SEO />
        <Layout>
          <TopContainer>
            <FeaturedProject />
          </TopContainer>
          <Container>
            <Section>
              <Column>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Intro width="900px" center>
                    Creandia är en event- & kommunikationsbyrå. Vi ger liv åt
                    dina visioner, och ser till att alltid leverera upplevelser
                    i absolut toppklass. Vårt team är din partner genom hela
                    processen, från idé och plan till genomförande och
                    utvärdering.
                  </Intro>
                </ScrollAnimation>
              </Column>
            </Section>
            <Section>
              <Grid columns="2fr 1fr">
                <Column>
                  <Line />
                  <Heading>
                    Uppdrag <span>Ett urval.</span>
                  </Heading>
                </Column>
              </Grid>

              <Projects limit={6} />
            </Section>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

export default IndexPage
